import React, {useEffect, useState} from 'react';
import AccessoriesLine from './AccessoriesLine';
import Loader from '../../../loader';
import sAction from 'sAction';
import Button from '../../../formElements/Button';

export default function Accessories({productId, changeLineVal, accessories = [], lineIndex}) {
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(true);
    const amount = parseInt(sAction.dataGet(`view/customData/customLines/lines/${lineIndex}/amount`));

    useEffect(() => {
        if (productId) {
            getProductAccessories(productId);
        }
    }, [productId]);

    useEffect(() => {
        changeLineVal(sumPrices(), 'price_accessories_and_installation', true);
    }, [amount]);

    const getProductAccessories = (id) => {
        setLoad(true);
        sAction.rest.post('getProductAccessories', {id}, (res) => {
            if (res.status) {
                sAction.dataSet(`view/customData/accessories/${lineIndex}`, res.data);
                changeLineVal(sumPrices(res.data), 'price_accessories_and_installation', true);
                setLoad(false);
            }
            setLoad(false);
        }, false);
    }

    const changeVal = (value, index, field, calcPrice = false) => {
        const newAccessories = accessories;
        newAccessories[index][field] = value;
        sAction.dataSet(`view/customData/accessories/${lineIndex}`, newAccessories);

        if (field === 'price' && calcPrice) {
            changeLineVal(sumPrices(newAccessories), 'price_accessories_and_installation', true);
        }
    }

    const addLine = () => {
        const newAccessories = accessories;
        // Generate random number as id for new line
        newAccessories.push({accessoryId: '', name: '', price: 0, id: Math.floor(Math.random() * 1000000) + 1});
        sAction.dataSet(`view/customData/accessories/${lineIndex}`, newAccessories);
    }

    const deleteLine = (index) => {
        const newAccessories = accessories;
        const removedRecord = newAccessories[index];

        // Když je id číslo je nový takže úplně smazat
        if (Number.isInteger(removedRecord.id)) {
            newAccessories.splice(index, 1);
        } else {
            // Když je id string tak jen označit jako smazaný udělá se soft delete v DB
            newAccessories[index].deleted = 1;
        }

        sAction.dataSet(`view/customData/accessories/${lineIndex}`, newAccessories);

        if (removedRecord.price != 0) {
            // Nevím proč když to není v timeoutu tak se to do řádku počítá se starou hodnotou
            setTimeout(() => {
                changeLineVal(sumPrices(newAccessories), 'price_accessories_and_installation', true);
            }, 5);
        }
    }

    const sumPrices = (data) => {
        const countData = data ? data : accessories;
        let sum = 0;
        countData?.forEach((accessory) => {
            // Když je řádek smazaný tak nepočítat
            if (accessory?.deleted !== 1) {
                sum += parseFloat(accessory.price);
            }
        });
        return (sum * amount)?.toFixed(2);
    }

    const renderAccessories = () => {
        if (!accessories) {
            return null;
        }
        return accessories?.map((accessory, index) => {
            if (accessory.deleted === 1) {
                return null;
            }
            return (
                <AccessoriesLine
                    key={accessory.id}
                    accessoryId={accessory.accessoryId}
                    accessoryName={accessory.name}
                    accessoryPrice={accessory.price}
                    index={index}
                    id={accessory.id}
                    changeVal={changeVal}
                    deleteLine={deleteLine}
                    productId={productId}
                />
            )
        });
    }

    return (
        <div className={`accessories accessories-${open ? 'open' : 'close'}`}>
            <div onClick={() => setOpen(!open)} className='accessories__header'>
                <span className={`icon-triangle accessories__header-${open ? 'open' : 'close'}`} />
                <div>{sAction.translate('LBL_MODULE_NAME', 'acm_accessories')}</div>
            </div>
            {open &&
                <>
                    <div className='accessories__linesHeader'>
                        <div>{sAction.translate('LBL_NAME', 'acm_accessories')}</div>
                        <div>{sAction.translate('LBL_PRICE', 'acm_accessories')}</div>
                    </div>
                    <div className='accessories__items'>
                        {load ? <Loader /> : renderAccessories()}
                        <Button className='accessories__add' onClick={() => addLine()}>
                            <div className='actionPanelButtonIcon icon-addIcon' />
                            {sAction.translate('LBL_ADD_NEW_LINE')}
                        </Button>
                    </div>
                </>
            }
        </div>
    );
}
