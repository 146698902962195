import React, {useState, useEffect} from "react";
import InputText from "../../../formElements/InputText";
import Relate from "../../../formElements/Relate";
import sAction from "sAction";

export default function AccessoriesLine({
    accessId,
    accessoryName,
    accessoryPrice,
    index,
    changeVal,
    deleteLine,
    productId,
}) {
    const [accessoryId, setAccessoryId] = useState(accessId || '');
    const [name, setName] = useState(accessoryName || '');
    const [price, setPrice] = useState(accessoryPrice || 0);

    useEffect(() => {
        setAccessoryId(accessId);
    }, [accessId]);

    useEffect(() => {
        setName(accessoryName);
    }, [accessoryName]);

    useEffect(() => {
        setPrice(accessoryPrice);
    }, [accessoryPrice]);

    const selectCallback = (newAccessoryId, newName) => {
        if (!newAccessoryId) {
            return;
        }
        sAction.rest.post('getAccessoryPrice', {id: newAccessoryId || ''}, (res) => {
            if (res.status) {
                setAccessoryId(newAccessoryId);
                setName(newName);
                setPrice(res.data);
                changeVal(newAccessoryId, index, 'accessoryId');
                changeVal(newName, index, 'name');
                changeVal(res.data, index, 'price', true);
            }
        }, false);
    }

    const changePrice = (value, calcPrice) => {
        setPrice(value)

        if (calcPrice) {
            // Nevím proč když to není v timeoutu tak se to do řádku počítá se starou hodnotou
            setTimeout(() => {
                changeVal(value, index, 'price', calcPrice);
            }, 5);
        }
    }

    const defaultAccessoryFilter = [
        {
            operandType: 'relate',
            parentId: productId,
            module: 'acm_calculation_products',
            relationship: ['acm_calculation_products_acm_accessories_1'],
            type: 'eq',
        }
    ];

    return (
        <div className="accessories__line">
            <span
                className='accessories__line-delete icon-deleteIcon'
                onClick={() => deleteLine(index)}
            />
            <Relate
                newRecord={false}
                containerClassName='accessories__field'
                callback={e => selectCallback(e.id, e.name)}
                module={'acm_accessories'}
                data={{
                    value: name || '',
                }}
                updateField = {true}
                InputProps={{
                    onBlur: (e) => changeVal(e.target.value, index, 'name')
                }}
                defaultFilter={defaultAccessoryFilter}
            />
            <InputText
                type={'number'}
                className='accessories__field'
                value={price || 0}
                onChange={e => changePrice(parseFloat(e.target.value), false)}
                onBlur={e => changePrice(parseFloat(e.target.value).toFixed(2), true)}
            />
        </div>
    )
}
