import React from "react";
import PureComponent from "../../pure";
import InputText from "../../formElements/InputText";
import Checkbox from "../../formElements/TickBox";
import Select from "../../formElements/Select";
import Relate from "../../formElements/Relate";

import sAction from 'sAction';
import AcmDate from "../../formElements/AcmDate";
import AcmDatetime from "../../formElements/AcmDatetime";
import Accessories from "./Accessories/Accessories";

export default class CustomLine extends PureComponent {


    constructor(props) {
        super(props);

        this.state = { cols: [], formula: {}, calcProductId: null};
        const def = this.props.def;
        const data = this.props.data;
        this.currentModule = sAction.dataGet(this.props.way + '/module');
        def.get('fields').toJS().forEachObject((col, key) => {
            this[col] = React.createRef();
        });
        this.permissions = this.props.canEdit;

        this.saveValue = this.saveValue.bind(this);
        this.doSwitch = this.doSwitch.bind(this);
        this.doMath = this.doMath.bind(this);
        this.changeVal = this.changeVal.bind(this);

    }

    moveElement (direction) {
        this.props.moveElement(this.props.lineKey, direction);
    }

    getLineButtons(key) {
        var eye = null;
        var minWidth = 0;
        var detail = null;
        var deleteIcon = null;
        let orderArrows = null;
        const renderFieldLabels = this.props.info.get('renderFieldLabels');
        if (sAction.hasAccess(this.props.info.get('module'), "edit")) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual' && this.props.info.get('module') !== 'acm_calculation_quote_lines') {
                minWidth += 14;
                orderArrows = (
                    <div className='customLinesCellEye customLinesCellArrows'>
                        <div className='icon-arrowUp prodLineIcon hoverBlue actionPanelButtonIcon' onClick={e => this.moveElement('up')} title={sAction.translate('LBL_MOVE_UP')}></div>
                        <div className='icon-arrowDown prodLineIcon hoverBlue actionPanelButtonIcon' onClick={e => this.moveElement('down')} title={sAction.translate('LBL_MOVE_DOWN')}></div>
                    </div>
                );
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), "detail")) {
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
                if (this.props.data.get('id')) {
                    eye = (<div className='customLinesCellEye'><div className='icon-eye detailViewFieldIconEye' onClick={e => this.eyeIconCallback()} title={sAction.translate('LBL_OPEN_LINE_IN_RIGHT_PANEL')}></div></div>);
                } else {
                    eye = (<div className='customLinesCellEye'>&nbsp;</div>);
                }
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
                if (this.props.data.get('id')) {
                    detail = (<div className='customLinesCellEye'><a href={'#detail/' + this.props.info.get('module') + '/' + this.props.data.get('id')} target='_blank' title={sAction.translate('LBL_OPEN_LINE_DETAIL_VIEW')}><div className='actionPanelButtonIcon icon-editDesc customLinesButton'></div></a></div>);
                } else {
                    detail = (<div className='customLinesCellEye'>&nbsp;</div>);
                }
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), "edit")) {
            minWidth += 16;
            deleteIcon = (
                <div className='customLinesCellEye' style={{marginLeft: '2px'}}>
                    <div title={sAction.translate('LBL_DELETE_LINE')} className='actionPanelButtonIcon icon-deleteIcon' onClick={e => this.deleteRecord()}></div>
                </div>
            );
        }

        return (
            <div
                className={`customLinesCell ${renderFieldLabels ? 'customLinesCellDeleteCustom' : 'customLinesCellDelete'}`}
                key={key}
                style={{minWidth: minWidth + 'px',}}
            >
                {orderArrows}
                {eye}
                {detail}
                {deleteIcon}
            </div>
        );
    }
    getField(col, data, key) {
        if (col.includes('break_line')) {
            return <div style={this.props.def?.get('style')?.get(col)?.toJS()} key={key} className="customLinesBreak">{
                    sAction.translate(col.split(' ')?.[1], this.props.info.get('module'))}
                </div>;
        }
        const moduleData = this.props.moduleData;
        const customLinesData = this.props.customLinesData?.toJS()?.[this.props.lineKey] || {};
        const colDef = moduleData.get(col);
        const def = this.props.def;
        var defaultValue = data.get ? data.get(col) : null;
        var className = '';
        const disabled = this.permissions ? colDef.get('readonly') : true;
        var style = null;
        var timelineStyle = null;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }
        var extraClass = "";
        if (def.get && def.get('extraClass') && def.get('extraClass').get && def.get('extraClass').get(col)) {
            extraClass = def.get('extraClass').get(col);
        }
        if (this.props.showError === true && colDef.get('required') === true && ((typeof defaultValue === 'string' && defaultValue.trim() === '') || (!defaultValue && defaultValue !== 0))) {
            extraClass += ' error';
        }
        if (this.props.timeline && this.props.timeline.get('field') === col) {
            if (style == null) {
                style = {};
            }
            style.color = 'white';
            style.backgroundColor = this.props.timeline.get('colors').get(defaultValue);
            timelineStyle = {color: 'white'};
        }

        const customStyle = this.props.customStyle;
        if (customStyle) {
            style = {...style, ...customStyle?.[col]};
        }

        const renderFieldLabels = this.props.info.get('renderFieldLabels');
        const customLabelBoxClass = renderFieldLabels ? 'customLinesCellLabel' : 'customLinesCellNoLabel';

        switch (colDef.get('type')) {
            // TODO přidat další typy
            case 'bool':
                this[col].current = { value: data[key] };

                if (defaultValue === '0') {
                    defaultValue = false;
                }

                return (
                    <div
                    className={'customLinesCell '+extraClass}
                    key={key}
                    style={style}>
                        <Checkbox
                            checked={defaultValue || false}
                            onChange={e => { this[col].current.value = e; this.changeVal(e, col); }}
                            disabled={disabled}
                        />
                    </div>
                );
            case 'relate':
                return (
                    <div key={key} style={style} className={customLabelBoxClass}>
                        <div className="linesLabel">{sAction.translate(colDef.get('vname'), this.props.info.get('module')) || 'd'}</div>
                        <Relate
                            key={key}
                            newRecord={false}
                            buttons={[]}
                            containerClassName={'customLinesCell'+extraClass}
                            inputClassName={"withBorder"}
                            containerStyle={!renderFieldLabels ? style : null}
                            callback={e => {this.changeVal(e.id, colDef.get('id_name')); sAction.dataSet(this.props.way + '/customData/customLines/lines/' + this.props.lineKey + '/' + col, e.name)}}
                            module={colDef.get('module')}
                            data={{
                                value: defaultValue || '',
                            }}
                            updateField = {true}
                            defaultFilter={customLinesData[col]?.defaultFilter}
                            quickSearchClass={'quickSearchResultExtend'}
                        />
                    </div>
                    );
            case 'enum':
                var optionsToSelect = [];
                if (def.customOptions && def.customOptions[col]) {
                    def.customOptions[col].forEachObject((opt) => {
                        optionsToSelect.push({ value: opt.value, label: sAction.translate(opt.label) });
                    });
                } else {
                    const options = sAction.app_strings[colDef.get('options')];
                    options?.forEachObject((value,key) => {
                        optionsToSelect.push({ value: key, label: value });
                    })
                }
                if (defaultValue === null || defaultValue === undefined) {
                    defaultValue = colDef.get('default');
                }
                if (this.props.info.get('module') === 'acm_calculation_quote_lines'
                && col === 'months_of_service'
                && this.props.data.get('category') === 'A4') {
                    optionsToSelect.pop();
                }

                return (
                    <div key={key} style={style} className={customLabelBoxClass}>
                        <div className="linesLabel">{sAction.translate(colDef.get('label'), this.props.info.get('module'))}</div>
                            <Select
                                containerClassName={"customLinesCell "+extraClass}
                                className="withBorder"
                                disabled={disabled}
                                key={key}
                                value={defaultValue || ''}
                                myRef={this[col]}
                                onChange={e => { this[col].current.value = e.target.value; this.changeVal(e.target.value, col); }}
                                options={optionsToSelect}
                                containerStyle={!renderFieldLabels ? style : null}
                                style={timelineStyle || null}
                            />
                    </div>
                );
            case 'currency':
                className = ' alignRight';
                if (!defaultValue && defaultValue !== 0 && defaultValue !== '0') {
                    defaultValue = '';
                } else if (typeof defaultValue === 'string' ) {
                    defaultValue = parseFloat(defaultValue).toFixed(2);
                }

                return (
                    <div key={key} style={style} className={customLabelBoxClass}>
                        <div className="linesLabel">
                            {renderFieldLabels && sAction.translate(colDef.get('label'), this.props.info.get('module'))}
                        </div>
                        <InputText
                            type={'number'}
                            containerClassName={"customLinesCell "+extraClass}
                            className={"withBorder alignRight"}
                            disabled={disabled}
                            key={key}
                            value={defaultValue}
                            myRef={this[col]}
                            onChange={e => this.changeVal(parseFloat(e.target.value), col, false)}
                            onBlur={e => this.changeVal(this.formatCurrency(defaultValue), col, true)}
                            containerStyle={!renderFieldLabels ? style : null}
                        />
                    </div>
                );
            case 'float':
            case 'decimal':
            case 'int':
                if (!defaultValue && defaultValue !== 0 && defaultValue !== '0') {
                    defaultValue = '';
                } else {
                    defaultValue = Math.round(defaultValue * 100) / 100;
                }
                return (
                        <div key={key} style={style} className={customLabelBoxClass}>
                            <div className="linesLabel">{sAction.translate(colDef.get('label'), this.props.info.get('module'))}</div>
                            <InputText
                                type={'number'}
                                containerClassName={"customLinesCell "+extraClass}
                                className={"withBorder" + className}
                                disabled={disabled}
                                key={key}
                                value={defaultValue}
                                initPrevValue={defaultValue}
                                myRef={this[col]}
                                onChange={e => this.changeVal(parseFloat(e.target.value), col, false)}
                                onBlur={(e, prevValue) => this.changeVal(parseFloat(e.target.value), col, true, prevValue)}
                                containerStyle={!renderFieldLabels ? style : null}
                            />
                        </div>
                );
            case 'date':
                return (
                    <div
                        className={"customLinesCell acmInputContainer "+extraClass}
                        style={!renderFieldLabels ? style : null}
                        key={key}
                    >
                        <AcmDate
                            type={'text'}
                            placeholder=' '
                            className="withBorder"
                            disabled={disabled}
                            value={defaultValue || null}
                            myRef={this[col]}
                            onChange={e => this.changeVal(e, col)}
                        />
                    </div>
                );
            case 'datetime':
                return (
                    <div
                        className={"customLinesCell acmInputContainer "+extraClass}
                        style={!renderFieldLabels ? style : null}
                        key={key}
                    >
                        <AcmDatetime
                            type={'text'}
                            placeholder=' '
                            className="withBorder"
                            disabled={disabled}
                            value={defaultValue || null}
                            myRef={this[col]}
                            onChange={e => this.changeVal(e.target.value, col)}
                        />
                    </div>
                );
            case 'id':
            case 'text':
            case 'name':
            case 'varchar':
            default:
                return (
                    <div key={key} style={style} className={customLabelBoxClass}>
                        <div className="linesLabel">{sAction.translate(colDef.get('label'), this.props.info.get('module'))}
                            <InputText
                                type={'text'}
                                containerClassName={"customLinesCell "+extraClass}
                                className="withBorder"
                                disabled={disabled}
                                key={key}
                                value={defaultValue || ''}
                                myRef={this[col]}
                                onChange={e => this.changeVal(e.target.value, col, false)}
                                onBlur={(e, prevValue) => this.changeVal(e.target.value, col, true, prevValue)}
                                containerStyle={!renderFieldLabels ? style : null}
                            />
                        </div>
                    </div>
                );
        }
    }

    /** doCustomLogic - provolava dalsi custom fce, vzniklo kvuli castemu provolavani onChange u varcharu */
    changeVal(val, key, doCustomLogic = true, prevValue) {
        let valToSave = val;
        if (typeof val === 'object' && val.name !== undefined) {
            valToSave = val.name;
        }
        this.saveValue(key, valToSave);
        if (doCustomLogic) {
            this.callCustomClassUpdate(key, val, prevValue);

            // Starsi funkcionalita. Nechavam kvuli zpetne kompatibilite + potencialnimu budoucimu predelani
            this.doSwitch(key, valToSave);
            this.doMath(key);
        }
    }

    callCustomClassUpdate(key, val, prevValue) {
        const data = {
            field: key,
            value: val,
            // prevValue v soucasne dobe neni podporovana. Muselo by se kompletne predelat renderovani fieldu.
            prevValue: prevValue,
            module: sAction.dataGet(this.props.way + '/module'),
            prefix: this.props.way + '/customData/customLines/lines/' + this.props.lineKey,
            way: this.props.way,
            lineIndex: this.props.lineKey,
            saveField: (val, key, doCustomLogic = true) => this.changeVal(val, key, doCustomLogic),
        };
        sAction.custom('updateDetailLine', data);

        // Dotáhnout doplňky pro tiskárny
        if (this.currentModule === 'acm_calculation_quote' && key === 'acm_calcul4965roducts_ida' && val) {
            this.setState({calcProductId: val || null});
        }
    }

    doSwitch(key, val, soft = false) {
        const def = this.props.def;
        if (!def.get('switch') || !def.get('switch').get(key)) {
            return;
        }
        if (def.get('switch').get(key) === true) {
            if (!soft) {
                this.state.formula = { global: val };
                this.setState({ formula: { global: val } });
            } else {
                var formula = Object.assign({}, this.state.formula);
                formula['global'] = val;
                this.state.formula = formula;
                // this.setState({ formula: formula });
            }
        } else {
            var formula = Object.assign({}, this.state.formula);
            def.get('switch').get(key).toJS().forEach(colName => {
                formula[colName] = val;
            });
            this.state.formula = formula;
            if (!soft) {
                this.setState({ formula: formula });
            }
        }
    }

    checkFormulaParent(formulas, formulaName) {
        if (formulas[formulaName]) {
            if (typeof formulas[formulaName] === 'string') {
                formulaName = this.checkFormulaParent(formulas, formulas[formulaName]);
            }
            return formulaName;
        }

        return 'default';
    }

    getFormulaName(key, formulas) {
        var formulaName = 'default';
        if (this.state.formula[key]) {
            formulaName = this.state.formula[key];
        } else if (this.state.formula['global']) {
            formulaName = this.state.formula['global'];
        }
        if (!formulas[key][formulaName]) return 'default';
        if (typeof formulas[key][formulaName] === 'string') formulaName = this.checkFormulaParent(formulas[key], formulas[key][formulaName]);

        return formulaName;
    }

    getFormulaReferenceKey(key, formulas) {
        var formulaDefinition = formulas[key];
        if (typeof formulaDefinition === 'string') {
            return this.getFormulaReference(formulaDefinition, formulas);
        }

        return key;
    }

    doMath(keyC) {
        var key = keyC;
        const formulas = this.props.def.get('formula').toJS();
        if (!formulas || !formulas[key]) {
            return;
        }
        if (typeof formulas[key] === 'string') {
            key = this.getFormulaReferenceKey(formulas[key], formulas);
        }
        var formulaName = this.getFormulaName(key, formulas);
        if (typeof formulas[key][formulaName] !== 'object') {
            return;
        }

        sAction.dsClear();
        const formula = formulas[key][formulaName];
        formula.forEachObject((formDef, colKey) => {
            var stack = [];
            formDef.forEach((form) => {
                switch (form.type) {
                    case 'num':
                        var num = parseFloat(form.value);
                        if (isNaN(num)) {
                            num = 0;
                        }
                        stack.push(num);
                        break;
                    case 'var':
                        if (this[form.value].current.value == undefined) {
                            return false;
                        }
                        var num = this[form.value].current.value;
                        if (typeof num === 'boolean') {
                            num = num ? 1 : 0;
                        }
                        num = parseFloat(num);
                        if (isNaN(num)) {
                            num = 0;
                        }
                        stack.push(num);
                        break;
                    case 'op':
                        if (typeof stack[stack.length - 1] == 'number' && typeof stack[stack.length - 2] == 'number') {
                            switch (form.value) {
                                case '+':
                                    stack[stack.length - 2] = stack[stack.length - 2] + stack.pop();
                                    break;
                                case '-':
                                    stack[stack.length - 2] = stack[stack.length - 2] - stack.pop();
                                    break;
                                case '*':
                                    stack[stack.length - 2] = stack[stack.length - 2] * stack.pop();
                                    break;
                                case '/':
                                    stack[stack.length - 2] = stack[stack.length - 2] / stack.pop();
                                    break;
                                default:
                                    return false;
                            }
                        } else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            });
            if (stack.length != 1) {
                return false;
            }
            if (this[colKey] && this[colKey].current) {
                this[colKey].current.value = this.formatCurrency(stack[0]);
            }
            sAction.dsAdd("set", this.props.way + '/customData/customLines/lines/' + this.props.lineKey + '/' + colKey, Math.round(stack[0] * 100) / 100);
            // sAction.dsAdd("set", this.props.way + '/changes/customData/customLines/' + this.props.lineKey + '/' + colKey, Math.round(stack[0] * 100) / 100);
        });
        sAction.dsProcess();
    }
    formatCurrency(value) {
        value = (Math.round(value * 100) / 100).toFixed(2);

        return value;
    }

    saveValue(key, value) {
        sAction.dsClear();
        sAction.dsAdd("set", this.props.way + '/customData/customLines/lines/' + this.props.lineKey + '/' + key, value);
        sAction.dsAdd("set", this.props.way + '/changes/forceChange', true);
        sAction.dsProcess();
    }
    deleteRecord() {
        this.saveValue('deleted', '1');
        // sAction.dsClear();
        // sAction.dsAdd('delete', this.props.way + '/customData/customLines/lines', this.props.lineKey);
        // sAction.dsProcess();
    }

    eyeIconCallback() {
        const disMod = this.props.info.get('module'); // modul
        const data = this.props.data;
        const id = data.get ? data.get('id') : null;
        const index = this.props.index;
        const rightPanelData = {
            "reloadList" : this.props.prefix,
        }
        sAction.setStorage("listViewSearchIndex", index);
        sAction.rightPanelDetail(disMod, id,rightPanelData);
    }

    getEyeIcon() {
        if (sAction.hasAccess(this.props.info.get('module'), "detail") && this.props.data.get('id')) {
            return (<div className='customLinesCell customLinesCellEye' key='eye'><div className='icon-eye detailViewFieldIconEye' onClick={e => this.eyeIconCallback()}></div></div>);
        }
        return (<div className='customLinesCell customLinesCellEye' key='eye'></div>);
    }

    render() {
        var data = this.props.data;
        const def = this.props.def;
        var cols = [];
        def.get('fields').toJS().forEachObject((col, key) => {
            cols.push(this.getField(col, data, key));
            if (def.get('switch') && def.get('switch').get(col)) {
                this.doSwitch(col, data.get(col), true);
            }
        });
        const lineButtons = this.props.canEdit ? this.getLineButtons('deleted', {}, cols.length) : (<div className='customLinesCell customLinesCellDelete'></div>);

        return (
            <div className={`customLinesRowContainer ${this.props.info.get('renderFieldLabels') ? 'customLinesRowContainerDivider' : ''}`}>
                {lineButtons}
                {cols}
                {this.currentModule === 'acm_calculation_quote' &&
                    <Accessories
                        productId={this.state.calcProductId}
                        changeLineVal={this.changeVal}
                        accessories={this.props.accessories?.toJS()?.[this.props.lineKey]}
                        lineIndex={this.props.lineKey}
                    />
                }
            </div>
        );
    }
}
