export default function merkInfo(data){
    const token = this.dataGet(`conf/merkToken`) ?? '';
    if(!token){
        this.error(this.translate('LBL_MERK_MISSING_TOKEN'));
        return;
    }

    let getIcoPath = '';
    switch (data.module){
        case 'Accounts':
        case 'Leads':
            getIcoPath = `${data.prefix}/fields/ticker_symbol/value`;
        break;
        default:
            getIcoPath = '';
    }
    const regNo = this.dataGet(getIcoPath) ?? '';
    if(!regNo){
        this.error(this.translate('LBL_MERK_MISSING_ICO'));
        return;
    }
    window.open(`https://api.merk.cz//embed/company/?regno=${regNo}&token=${token}`, '_blank')
}